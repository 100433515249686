/* eslint-disable */
export default {
  "dirty": false,
  "raw": "v1.14.2-1-g9f43c1a2",
  "hash": "g9f43c1a2",
  "distance": 1,
  "tag": "v1.14.2",
  "semver": {
    "options": {
      "loose": false,
      "includePrerelease": false
    },
    "loose": false,
    "raw": "v1.14.2",
    "major": 1,
    "minor": 14,
    "patch": 2,
    "prerelease": [],
    "build": [],
    "version": "1.14.2"
  },
  "suffix": "1-g9f43c1a2",
  "semverString": "1.14.2+1.g9f43c1a2"
};
